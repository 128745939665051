import { Helmet } from 'react-helmet-async'
import Brands from 'src/components/brands/ItemsBrands/ItemsBrands'
import Seo from 'src/components/seo/Seo'

function Stores() {
  return (
    <>
      <Helmet>
        <meta name="keywords" content="marcas" />
      </Helmet>
      <Seo
        title="Nossas Marcas | Decathlon"
        description="Nossas Marcas | Decathlon"
        canonical="https://www.decathlon.com.br/marcas"
      />
      <Brands />
    </>
  )
}

export default Stores
